import React from "react";
import Layout from "../components/Layout";
import parse from "html-react-parser";
import { injectIntl } from "gatsby-plugin-intl";
import { preparationForTestsArray } from "../utils/publicConditionObjects";

//@ts-ignore
const preparationForTests = ({ intl }) => (
  <Layout>
    <div className="grid  lg:justify-center sm:justify-left xs:justify-center  bg-[#FAFAFA]  ">
      <div className="flex justify-center items-center  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] ">
        <div className="xl:w-full mb-10 mt-5 flex flex-col   px-2  ">
          <h1 className="text-center lg:text-4xl md:text-2xl xs:text-[1.4rem] font-['gabdugi'] text-[#DA6A00] font-semibold  my-[1rem] md:font-bold sm:flex">
            {parse(
              intl.formatMessage({
                id: "PreparationForTests.PreparationForTestHeader",
              })
            )}
          </h1>
          <p className="lg:text-4xl md:text-2xl xs:text-[1.4rem] font-['gabdugi'] text-[#DA6A00] font-semibold  my-[1rem] md:font-bold sm:flex">
            {parse(
              intl.formatMessage({
                id: "PreparationForTests.PreparationForTestTitle",
              })
            )}
          </p>
          <div className=" flex flex-col mb-3 xl:w-full justify-start items-start">
            <div className=" font-['gabdugi'] text-xl text-justify ">
              {parse(
                intl.formatMessage({
                  id: "PublicCondition.PublicConditionsText",
                })
              )}
            </div>
            {preparationForTestsArray.map((parts) => (
              <div className="mt-4 font-['gabdugi'] text-xl text-justify ">
                {parse(intl.formatMessage({ id: `PublicCondition.${parts}` }))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default injectIntl(preparationForTests);
